import { ServiceTypes } from '~/types';

const resolveSortedBuyLinks = () => [
  ServiceTypes.BANDCAMP,
  ServiceTypes.ITUNES_STORE,
  ServiceTypes.AMAZON,
  ServiceTypes.BEATPORT,
  ServiceTypes.TRAXSOURCE,
];

export default resolveSortedBuyLinks;
