import Debug from 'debug';

import { Item } from '~/app/lib/songwhipApi/mapper';
import { Links, ServiceTypes } from '~/types';

import resolveSortedStreamLinks from './lib/resolveSortedStreamLinks';
import localizeLinks, { LinkLocalized } from './lib/localizeLinks';
import resolveSortedBuyLinks from './lib/resolveSortedBuyLinks';
import pickWithKey from './lib/pickWithKey';

const debug = Debug('songwhip/utils/resolveLinksForCountry');

export type LinkResolved = LinkLocalized & {
  key: ServiceTypes;
};

export type LinksResolved = LinkResolved[];

const resolveLinksForCountry = (item: Item, forCountry: string) => {
  debug('resolve links for %s', forCountry);

  if (!item) throw new Error('`item` is required');
  if (!forCountry) throw new Error('`forCountry` is required');

  const { links, sourceCountry } = item;
  if (!links) return;

  // ensure correct casing
  forCountry = forCountry.toUpperCase();

  const linksLocalized = localizeLinks({
    links,
    sourceCountry,
    forCountry,
  });

  const linksToStream = pickWithKey(
    resolveSortedStreamLinks(forCountry),
    linksLocalized
  ) as LinksResolved;

  const linksToBuy = pickWithKey(
    resolveSortedBuyLinks(),
    linksLocalized
  ) as LinksResolved;

  return {
    stream: linksToStream.length ? linksToStream : undefined,
    buy: linksToBuy.length ? linksToBuy : undefined,
  };
};

export const toLocalLinks = (
  {
    links,
    sourceCountry,
  }: { links?: Links; sourceCountry?: string | undefined },
  forCountry: string
) => {
  debug('to local links for %s', forCountry);
  if (!links) return;

  return localizeLinks({
    links,
    sourceCountry,
    // ensure correct casing
    forCountry: forCountry.toUpperCase(),
  });
};

export default resolveLinksForCountry;
