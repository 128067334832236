import mapCustomPage from '../mapper/mapCustomPage';
import songwhipApi from '../songwhipApi';
import { ItemConfig } from '../types';

export const getCustomPageApi = async ({
  customPageId,
}: {
  customPageId: number;
}) => {
  const {
    json: { data },
  } = await songwhipApi(`custom-pages/${customPageId}`);

  return mapCustomPage(data);
};

export const createCustomPageApi = async (params: {
  artistId: number;
  name: string;
  image?: string;
  isDraft?: boolean;
}) => {
  const {
    json: { data },
  } = await songwhipApi('custom-pages', {
    method: 'POST',
    body: params,
  });

  return mapCustomPage(data);
};

export const patchCustomPageApi = async ({
  customPageId,
  changes,
}: {
  customPageId: number;
  changes: {
    // Only support isDraft for now
    isDraft?: boolean;
  };
}) => {
  const {
    json: { data },
  } = await songwhipApi(`custom-pages/${customPageId}`, {
    method: 'PATCH',
    body: changes,
  });

  return mapCustomPage(data);
};

export const deleteCustomPageApi = async (customPageId: number) => {
  const {
    json: { data },
  } = await songwhipApi(`custom-pages/${customPageId}`, {
    method: 'DELETE',
  });

  return mapCustomPage(data);
};

export const patchCustomPageConfigApi = async ({
  customPageId,
  config,
}: {
  customPageId: number;
  config: ItemConfig;
}) => {
  if (!customPageId) throw new Error('`customPageId` required');

  const {
    json: { data },
  } = await songwhipApi(`custom-pages/${customPageId}/config`, {
    method: 'PATCH',
    body: config,
  });

  return mapCustomPage(data);
};
