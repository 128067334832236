import ApiError, { ApiErrorParams } from './ApiError';

export const serializeError = (error: Error | ApiError) => {
  if ('toJSON' in error) {
    return error.toJSON();
  }

  return {
    message: error.message,
    stack: error.stack,
  };
};

export const resolveError = (
  params:
    | {
        message: string;
        stack?: string;
      }
    | ApiError
    | Error
    | ApiErrorParams
    | undefined
): Error | ApiError | undefined => {
  if (!params) return;

  // already instance
  if (params instanceof ApiError || params instanceof Error) {
    return params;
  }

  if (params['type'] === 'ApiError') {
    return new ApiError(params as ApiErrorParams);
  }

  const { stack, message } = params;
  const error = new Error(message);
  if (stack) error.stack = stack;

  return error;
};
