import { ArtistConfig } from '../../songwhipApi/types';

/**
 * Resolve an images from various sources favouring customizations
 */
const resolveItemImage = ({
  config,
  image,
}: {
  config: ArtistConfig | undefined;
  image?: string;
}) =>
  config?.addons?.CUSTOM_IMAGE?.default?.url ||
  // legacy image customization schema, can remove once database has been migrated
  (config?.layout as any)?.background?.props?.url ||
  image;

export default resolveItemImage;
