import { MappedCustomPage } from '~/app/lib/songwhipApi/mapper';
import { getCustomPageApi } from '~/app/lib/songwhipApi/customPages';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { selectCustomPageValue } from '../selectors';
import { State, Dispatch } from '../../types';

import {
  FetchCustomPageStartAction,
  FetchCustomPageSuccessAction,
  FetchCustomPageErrorAction,
  CustomPagesActionTypes as ActionTypes,
} from '../types';

export const fetchCustomPage =
  ({ customPageId }: { customPageId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const existingRecord = selectCustomPageValue(state, customPageId);

    // don't fetch if record already in memory
    if (existingRecord) return;

    dispatch(fetchCustomPageStart(customPageId));

    try {
      const payload = await getCustomPageApi({ customPageId });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchCustomPageError(customPageId, error));
    }
  };

export const fetchCustomPageStart = (
  customPageId: number
): FetchCustomPageStartAction => ({
  type: ActionTypes.FETCH_CUSTOM_PAGE_START,
  customPageId,
});

export const fetchCustomPageSuccess = (
  payload: MappedCustomPage
): FetchCustomPageSuccessAction => ({
  type: ActionTypes.FETCH_CUSTOM_PAGE_SUCCESS,
  payload,
});

export const fetchCustomPageError = (
  customPageId: number,
  error: Error
): FetchCustomPageErrorAction => ({
  type: ActionTypes.FETCH_CUSTOM_PAGE_ERROR,
  customPageId,
  error,
});
