const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const TTL_DAYS = 7;

const isStale = (timestamp: number | undefined | null) => {
  // if no refreshedAt is defined then the item has never been
  // fetched directly so we only have 'shallow' data
  if (!timestamp) return true;

  const deltaMs = Date.now() - timestamp;
  const ttlMs = TTL_DAYS * ONE_DAY_MS;
  const isStale = deltaMs > ttlMs;

  return isStale;
};

export default isStale;
