import { createSelector } from 'reselect';

import { resolveError } from '~/app/lib/errors/utils';
import { ItemTypes } from '~/types';

import { selectUserCanEditItem } from '../session/selectors';
import { selectPrimaryOwnerAccount } from '../lib/selectors';
import { selectArtistGlobal } from '../artists/selectors';
import resolveItemImage from '../lib/resolveItemImage';
import { StatusTypes, State } from '../types';

import { SelectedCustomPage, StoredCustomPage } from './types';

const selectCustomPageStateItem = (state: State, customPageId: number) =>
  state.customPages[customPageId];

export const selectCustomPageValue = (state: State, customPageId: number) =>
  selectCustomPageStateItem(state, customPageId)?.value;

/**
 * Create a selector function that takes the redux State object
 * and an `customPageId` and returns a composed SelectedCustomPage. This
 * is nice because we can keep the underlying `MappedCustomPage`
 * largely similar to the `songwhip-api` payload but reshape
 * the `SelectedCustomPage` to better match the requirements of
 * the songwhip-web client.
 *
 * By default the result is derived from the `state.customPages` store,
 * but this can be overridden via the `selectStateItem` argument.
 * This is used in the 'edit mode' when we want to derive state
 * from the 'staged' CustomPage clone in `state.editStage`.
 *
 * Reselect is used to ensure we memo-ize the result and only return
 * a new object when select parts of the underlying input state change.
 */
export const createSelectCustomPage = ({
  selectStateItem = selectCustomPageStateItem,
  selectArtist = selectArtistGlobal,
}: {
  selectStateItem?: typeof selectCustomPageStateItem;

  /**
   * COMPLEX: By default we're using selectArtistGlobal instead of invoking
   * createSelectArtist() as multiple instances of selectCustomPage() cannot reuse
   * the same SelectedArtist memo cache. If you ever need to render multiple
   * CustomPages per page, you'll want a dedicated `selectCustomPage` instance for each
   * and should pass a dedicated `selectArtist` instance to createSelectCustomPage()
   * to ensure that the (1 entry) memo cache isn't being shared between all.
   */
  selectArtist?: typeof selectArtistGlobal;
} = {}) => {
  const selectValue = (state: State, customPageId: number) =>
    selectStateItem(state, customPageId)?.value;

  const selectIsLoading = (state: State, customPageId: number) =>
    selectStateItem(state, customPageId)?.status === StatusTypes.PENDING;

  const selectMainArtist = (state: State, customPageId: number) => {
    const artistIds = selectValue(state, customPageId)?.artistIds;
    const mainArtistId = artistIds?.[0];

    return mainArtistId ? selectArtist(state, mainArtistId) : undefined;
  };

  const selectError = (state: State, customPageId: number) => {
    const customPage = selectStateItem(state, customPageId);
    return resolveError(customPage?.error);
  };

  const selectUserCanEdit = (state: State, customPageId: number) =>
    selectUserCanEditItem(state, selectValue(state, customPageId));

  const createSelectKey =
    <TKey extends keyof StoredCustomPage>(key: TKey) =>
    (state: State, customPageId: number) =>
      selectValue(state, customPageId)?.[key];

  // PERF: Reselect is used to ensure that a new object is only output when
  // any of the core 'input selectors' return a DIFFERENT value. It's important
  // that the values returned from these function are as tightly scoped as possible.
  return createSelector(
    [
      createSelectKey('id'),
      createSelectKey('pagePath'),
      createSelectKey('name'),
      createSelectKey('config'),
      createSelectKey('image'),
      createSelectKey('ownedByAccounts'),
      createSelectKey('artistIds'),
      createSelectKey('customLinks'),
      createSelectKey('isDraft'),

      selectMainArtist,
      selectUserCanEdit,
      selectError,
      selectIsLoading,
    ],
    // this output function is only called when the
    // result of ANY of the above changes
    (
      id,
      pagePath,
      name,
      config,
      image,
      ownedByAccounts,
      artistIds,
      customLinks,
      isDraft,

      mainArtist,
      userCanEdit,
      error,
      isLoading
    ): SelectedCustomPage | undefined => {
      if (!id) return;
      if (!mainArtist) return;

      const primaryOwnerAccount = selectPrimaryOwnerAccount(ownedByAccounts);

      const result: SelectedCustomPage = {
        type: ItemTypes.CUSTOM_PAGE,
        id,
        name: name!,
        pagePath: pagePath!,
        artistId: mainArtist.id,
        artistName: mainArtist.name,
        artistPagePath: mainArtist.pagePath,
        artistIds: artistIds!,
        config,

        image: resolveItemImage({ config, image }),

        // The item needs to have an account with an active subscription to be `owned`.
        // Note: Orchard accounts always have an active subscription
        isOwned: !!ownedByAccounts?.length,
        pageBrand: primaryOwnerAccount?.orchardBrand,

        userCanEdit,
        error,
        isLoading,

        // used by the custom domains scoping feature to check if pages are in an account scope
        ownedByAccountIds: ownedByAccounts?.map(({ id }) => id),

        primaryOwnerAccount,
        customLinks,
        isDraft,

        // REVIEW: These hardcoded props are for compat with artist/album/track items
        isShallow: false,
        links: undefined,
        originalImage: undefined,
      };

      return result;
    }
  );
};

export const selectCustomPageGlobal = createSelectCustomPage();
