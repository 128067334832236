import Debug from 'debug';

import { Links, Link } from '~/types';

const debug = Debug('songwhip/utils/localizeLinks');

export type LinkLocalized = {
  link: string;
  localized: boolean;
};

export type LinksLocalized = {
  [serviceId: string]: LinkLocalized;
};

const localizeLinks = ({
  links,
  forCountry,
  sourceCountry,
}: {
  links: Links;
  forCountry: string;
  sourceCountry: string | undefined;
}) => {
  debug('localize links for %s', forCountry);

  sourceCountry = sourceCountry?.toUpperCase();
  forCountry = forCountry.toUpperCase();

  return Object.keys(links).reduce((result, service) => {
    let link: string | undefined;

    // first attempt to find a link for the user's country
    link = findLinkForCountry(links[service], forCountry);

    if (link) {
      // debug('found localized link for %s', service, link);
      result[service] = { link, localized: true };
    }

    // else find the source link
    if (!link) {
      // debug('find source country links for %s', service);
      link = findLinkForCountry(links[service], sourceCountry);
      if (link) result[service] = { link, localized: false };
      else debug('no local link found for %s', service);
    }

    // else find any link
    if (!link) {
      // debug('find any damn link for %s', service);
      link = findLinkForCountry(links[service]);
      if (link) result[service] = { link, localized: false };
      else debug('no link found for %s', service);
    }

    return result;
  }, {} as LinksLocalized);
};

const findLinkForCountry = (links: Link[] | null, country?: string) => {
  if (!links) return;

  const match = getLink(links, country);
  if (!match) return;

  country = country || (match.countries ? match.countries[0] : null);
  if (!country) return;

  return templateLink(match.link, country);
};

const getLink = (links: Link[], country?: string) => {
  if (!country) return links[0];

  // normalize country-code format
  country = country.toUpperCase();

  return find(
    links,
    ({ countries }) =>
      !countries || !countries.length || countries.indexOf(country) > -1
  );
};

const find = (items, test) => {
  for (let index = 0; index < items.length; index++) {
    const item = items[index];
    // eslint-disable-next-line jest/consistent-test-it
    if (test(item)) return item;
  }

  return undefined;
};

const templateLink = (link: string, country = '') =>
  link ? link.replace('{country}', country.toLowerCase()) : undefined;

export default localizeLinks;
