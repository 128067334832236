import mapTrack from '../mapper/mapTrack';
import songwhipApi from '../songwhipApi';
import { ItemConfig } from '../types';

type GetTrackParams = {
  trackId: number;
};

export const getTrackApi = async ({ trackId }: GetTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}`);

  return mapTrack(data);
};

type LocalizeTrackParams = {
  trackId: number;
  country: string;
};

export const localizeTrackApi = async ({
  trackId,
  country,
}: LocalizeTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}/localize`, {
    method: 'POST',
    body: {
      country,
    },
  });

  return mapTrack(data);
};

type RefreshTrackParams = {
  trackId: number;
  force?: boolean;
  country?: string;
};

export const refreshTrackApi = async ({
  trackId,
  force,
  country,
}: RefreshTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}/refresh`, {
    method: 'POST',
    body: {
      force,
      country,
    },
  });

  return mapTrack(data);
};

export const patchTrackConfigApi = async ({
  trackId,
  config,
}: {
  trackId: number;
  config: ItemConfig;
}) => {
  if (!trackId) throw new Error('`trackId` required');

  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}/config`, {
    method: 'PATCH',
    body: config,
  });

  return mapTrack(data);
};
