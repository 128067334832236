import { Dispatch } from 'redux';
import Debug from 'debug';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { refreshTrackApi } from '~/app/lib/songwhipApi/tracks';
import { State } from '~/app/lib/store/types';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { fetchTrackError, fetchTrackStart } from './fetchTrack';

const debug = Debug('songwhip/store/tracks/actions');

export const refreshTrack =
  ({ trackId }: { trackId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    debug('refresh: %s', trackId);

    if (!trackId) throw new Error('`trackId` required');

    dispatch(fetchTrackStart(trackId));

    try {
      const country = selectUserCountry(getState());
      const payload = await refreshTrackApi({ trackId, country });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (e) {
      const error = e as Error;
      dispatch(fetchTrackError(trackId, error));
    }
  };
