import { getTrackApi } from '~/app/lib/songwhipApi/tracks';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { MappedTrack } from '~/app/lib/songwhipApi/mapper';
import { selectTrackValue } from '../selectors';
import { State, Dispatch } from '../../types';

import {
  FetchTrackStartAction,
  FetchTrackSuccessAction,
  FetchTrackErrorAction,
  TracksActionTypes as ActionTypes,
} from '../types';

export const fetchTrack =
  ({ trackId }: { trackId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const existingRecord = selectTrackValue(state, trackId);

    // don't fetch if record already in memory
    if (existingRecord) return;

    dispatch(fetchTrackStart(trackId));

    try {
      const payload = await getTrackApi({ trackId });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchTrackError(trackId, error));
    }
  };

export const fetchTrackStart = (trackId: number): FetchTrackStartAction => ({
  type: ActionTypes.FETCH_TRACK_START,
  trackId,
});

export const fetchTrackSuccess = (
  payload: MappedTrack
): FetchTrackSuccessAction => ({
  type: ActionTypes.FETCH_TRACK_SUCCESS,
  payload,
});

export const fetchTrackError = (
  trackId: number,
  error: Error
): FetchTrackErrorAction => ({
  type: ActionTypes.FETCH_TRACK_ERROR,
  trackId,
  error,
});
