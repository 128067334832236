const arrayInsert = <T = any>({
  array,
  atIndex,
  item,
}: {
  array: T[];
  atIndex: number;
  item: T;
}) => [...array.slice(0, atIndex), item, ...array.slice(atIndex)];

export default arrayInsert;
