import { Dispatch } from 'redux';

import * as artistsApi from '~/app/lib/songwhipApi/artists';
import { State } from '../../types';

import {
  FetchArtistStartAction,
  FetchArtistSuccessAction,
  FetchArtistErrorAction,
  ArtistsActionTypes as ActionTypes,
} from '../types';

import { selectArtistGlobal } from '../selectors';
import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';

const fetchArtist =
  ({
    artistId,
    force = false,
  }: {
    artistId: number;

    /**
     * Force fetch artist even if already in the store,
     * callers can use this when they *really* need to
     * know the have the latest data.
     */
    force?: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const artist = selectArtistGlobal(state, artistId);

    // don't fetch if record already in memory
    if (artist && !artist?.error && !artist?.isShallow && !force) return;

    dispatch(fetchArtistStart(artistId));

    try {
      const payload = await artistsApi.getArtistApi({ artistId, force });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchArtistError(artistId, error));
    }
  };

export const fetchArtistStart = (artistId: number): FetchArtistStartAction => ({
  type: ActionTypes.FETCH_START,
  artistId,
});

export const fetchArtistSuccess = (
  params: Omit<FetchArtistSuccessAction, 'type'>
): FetchArtistSuccessAction => ({
  type: ActionTypes.FETCH_SUCCESS,
  ...params,
});

export const fetchArtistError = (
  artistId: number,
  error: Error
): FetchArtistErrorAction => ({
  type: ActionTypes.FETCH_ERROR,
  artistId,
  error,
});

export default fetchArtist;
