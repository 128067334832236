import { ServiceTypes } from '~/types';
import arrayInsert from '../../arrayInsert';
import arrayMove from '../../arrayMove';

const DEFAULT_LIST = [
  ServiceTypes.SPOTIFY,
  ServiceTypes.ITUNES,
  ServiceTypes.YOU_TUBE,
  ServiceTypes.TIDAL,
  ServiceTypes.AMAZON_MUSIC,
  ServiceTypes.SOUND_CLOUD,
  ServiceTypes.YOU_TUBE_MUSIC,
  ServiceTypes.DEEZER,
  ServiceTypes.AUDIOMACK,
  ServiceTypes.QOBUZ,
  ServiceTypes.NAPSTER,
  ServiceTypes.AUDIUS,
];

/**
 * Sorted by preferred service (usage data from google-analytics)
 */
const resolveSortedStreamLinks = (country: string): ServiceTypes[] => {
  switch (country) {
    // only US has pandora
    case 'US':
      return arrayInsert({
        array: DEFAULT_LIST,
        atIndex: 5,
        item: ServiceTypes.PANDORA,
      });

    // india likes gaana & jio-saavn
    case 'IN':
    case 'NP':
    case 'PK':
      return [ServiceTypes.GAANA, ServiceTypes.JIO_SAAVN, ...DEFAULT_LIST];

    // france like deezer, unsure where else, need more data
    case 'FR':
      return arrayMove({
        array: DEFAULT_LIST,
        toIndex: 2,
        item: ServiceTypes.DEEZER,
      });

    // japan prefers spotify, apple, amazon, then line
    case 'JP':
      return arrayInsert({
        array: arrayMove({
          array: DEFAULT_LIST,
          toIndex: 2,
          item: ServiceTypes.AMAZON,
        }),
        atIndex: 3,
        item: ServiceTypes.LINE_MUSIC,
      });

    // ussr likes yandex
    case 'RU':
    case 'AM':
    case 'AZ':
    case 'BY':
    case 'GE':
    case 'KZ':
    case 'KG':
    case 'MD':
    case 'TJ':
    case 'TM':
    case 'UZ':
      return arrayInsert({
        array: DEFAULT_LIST,
        atIndex: 0,
        item: ServiceTypes.YANDEX,
      });

    case 'ALL':
      return [
        ...DEFAULT_LIST,
        ServiceTypes.PANDORA,
        ServiceTypes.GAANA,
        ServiceTypes.JIO_SAAVN,
        ServiceTypes.YANDEX,
      ];

    default:
      return DEFAULT_LIST;
  }
};

export default resolveSortedStreamLinks;
