import { FC } from 'react';

import {
  AccountConfig,
  CustomBrand,
  OwnedByAccount,
} from '~/app/lib/songwhipApi/types';
import { toBitmapIcon } from '~/app/components/Icon/BitmapIcon';
import { IconProps } from '~/app/components/Icon/toIcon';

interface SelectedCustomBrand extends CustomBrand {
  Icon: FC<IconProps>;
}

export interface SelectedAccountConfig extends Omit<AccountConfig, 'brands'> {
  brands: SelectedCustomBrand[] | undefined;
}

export type SelectedPrimaryOwnerAccount = Omit<OwnedByAccount, 'config'> & {
  config: SelectedAccountConfig | undefined;
};

/**
 * The 'primary owner' is the MAIN owner Account of the page.
 * This is the Account that the page inherits config (such as
 * Custom Brands) from.
 *
 * There should never be more than one primary owner Account.
 * Multiple Account owners cases are rare (but possible)
 * so we must handle it.
 */
export const selectPrimaryOwnerAccount = (
  ownedByAccounts: OwnedByAccount[] | undefined
): SelectedPrimaryOwnerAccount | undefined => {
  // find the 'primary' owner
  const primaryOwner = ownedByAccounts?.find(({ isPrimary }) => !!isPrimary);
  if (!primaryOwner) return;

  const primaryOwnerConfig = primaryOwner.config;

  const config: SelectedAccountConfig = {
    ...primaryOwnerConfig,

    // create an Icon component ready to be rendered anywhere in the app
    brands: primaryOwnerConfig?.brands?.map((brand) => {
      return {
        ...brand,

        Icon: toBitmapIcon({
          src: brand.icon,
          dynamicColoring: !!brand.dynamicColoring,
        }),
      };
    }),
  };

  return {
    ...primaryOwner,
    config,
  };
};
