import { Dispatch } from 'redux';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { localizeTrackApi } from '~/app/lib/songwhipApi/tracks';
import { State } from '~/app/lib/store/types';

import { fetchTrackError, fetchTrackStart } from './fetchTrack';
import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';

export const localizeTrack =
  ({ trackId }: { trackId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const country = selectUserCountry(state);

    if (!country) throw new Error('user country undefined');

    dispatch(fetchTrackStart(trackId));

    try {
      const payload = await localizeTrackApi({ trackId, country });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchTrackError(trackId, error));
    }
  };
