import arrayInsert from './arrayInsert';

const arrayMove = <T = any>({
  array,
  toIndex,
  item,
}: {
  array: T[];
  toIndex: number;
  item: T;
}) => {
  const result = [...array];

  // remove the item
  result.splice(result.indexOf(item), 1);

  // create a new array inserting item at given index
  return arrayInsert({
    array: result,
    atIndex: toIndex,
    item,
  });
};

export default arrayMove;
